import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql } from "gatsby"

const StyledHeroImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
`

const StyledContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`

const StyledSectionTitle = styled.h2`
  color: #da1e35;
  font-size: 32px;
  text-align: center;
  padding: 0 20px;
`

const StyledSection = styled("div")`
  display: flex;
  flex-direction: column;

  p {
    color: #000;
    font-size: 15px;
    padding: 0 20px;
    text-align: justify;
  }
`

const StyledAuthorParagraph = styled.p`
color: #000;
    font-size: 15px;
    padding: 0 20px;
    text-align: justify;
    margin: 0;
`

const StyledListParagraph = styled.p`
  color: #000;
  font-size: 15px;
  padding: 0 20px;
  text-align: justify;
  margin: 5px 0;
`

const InvestmentsPage = ({ data }) => {
  return (
    <>
      <StyledHeroImage fluid={data.hero_image_painting.childImageSharp.fluid} />
      <StyledContentWrapper
        image={data.maps_background.childImageSharp.fluid.src}
      >
        <StyledSection>
          <StyledSectionTitle>INTELIGENTNY ROZWÓJ</StyledSectionTitle>
          <p>
            PROGRAM OPERACYJNY INTELIGENTNY ROZWÓJ 2014-2020, poddziałanie
            2.3.1.
          </p>
          <p>Proinnowacyjne usługi IOB dla MŚP</p>
          <p>Status – Konkurs rozstrzygnięty</p>
          <StyledSectionTitle>OPIS PROJEKTU</StyledSectionTitle>
          <p>Szanowni Państwo</p>
          <p>
            W związku z planowaną realizacją projektu w ramach POIR 2.3.1
            Proinnowacyjne usługi IOB dla MŚP, zapraszamy do udziału w
            postępowaniu na wybór wykonawcy proinnowacyjnych usług doradczych.
            Projekt obejmuje 10 powiązanych ze sobą usług doradczych dotyczących
            wdrożenia do produkcji innowacyjnego wielowarstwowego materiału
            izolacyjnego na bazie porosa dla sektora budowlanego, w tym:
          </p>
          <StyledListParagraph>
            • Analiza alternatywnych ścieżek rozwoju poprzez wdrażanie
            innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Przygotowanie szczegółowego modelu finansowego dla opracowywanej
            lub wdrażanej innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Identyfikacja i mapowanie kluczowych procesów biznesowych
            związanych z wdrażaniem innowacji, ich modyfikacji i optymalizacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Pomoc w opracowaniu dokumentacji funkcjonalnej lub technicznej
            niezbędnej do wdrożenia innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Analiza ryzyka wdrożenia innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Doradztwo, pomoc i szkolenia w pełnym wdrożeniu innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Monitorowanie i ocena efektów wdrażania innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Analiza wpływu wdrożenia innowacyjnej technologii na środowisko
            naturalne,
          </StyledListParagraph>
          <StyledListParagraph>
            • Opracowanie strategii marketingowej dla wyrobu lub usługi będącej
            przedmiotem wdrożenia innowacji,
          </StyledListParagraph>
          <StyledListParagraph>
            • Pozostałe uzasadnione doradztwo niezbędne do wdrożenia innowacji
            technologicznej,
          </StyledListParagraph>
          <p>
            Po realizacji usług doradczych nastąpi wdrożenie projektu do
            działalności gospodarczej poprzez zakup środków trwałych oraz
            uzyskania odpowiednich certyfikatów. Poniżej znajduje się zapytanie
            oraz formularz ofertowy, w którym zawarto wszelkie niezbędne
            informacje do sporządzenia oferty związanej z realizacją
            proinnowacyjnych usług doradczych. Wypełniony formularz należy
            przesłać do nas wraz z załącznikiem do dnia 28 sierpnia 2017r. do
            godziny 23:59.
          </p>
          <StyledAuthorParagraph>Z poważaniem</StyledAuthorParagraph>
          <StyledAuthorParagraph>
            <b>Marek Bartoszek</b>, Właściciel
          </StyledAuthorParagraph>
        </StyledSection>
        <StyledSection>
          <StyledSectionTitle>OPIS PROJEKTU</StyledSectionTitle>
          <p>
            W związku z planowaną realizacją projektu w ramach POIR 2.3.1
            Proinnowacyjne usługi IOB dla MŚP, zapraszamy do udziału w
            postępowaniu na wybór wykonawcy proinnowacyjnych usług doradczych.
            Informacja dotyczy zapytania ofertowego, dotyczącego przeprowadzenia
            proinnowacyjnych usług doradczych, w zakresie wdrożenia innowacji
            produktowej i procesowej w postaci „innowacyjnego wielowarstwowego
            materiału izolacyjnego na bazie poroso dla przemysłu budowlanego ”,
            w firmie PPHU MARCUS – świadczone przez Instytucje Otoczenia
            Biznesu, akredytowane w obszarze usług doradczych w zakresie
            innowacji. W postępowaniu z dnia 12.08.2017 wybrano ofertę IOB:
            Naczelnej Organizacji Technicznej Federacji Stowarzyszeń
            Naukowo-Technicznych Rada Miejska w Gliwicach 44-100 Gliwice, ul.
            Górnych Wałów 25 Data wpłynięcia oferty: 28.08.2017
          </p>
          <p>
            Informujemy o realizacji projektu „Wdrożenie na rynek innowacyjnego
            wielowarstwowego materiału izolacyjnego na bazie poroso dla
            przemysłu budowlanego” Przedmiotem projektu jest zakup
            proinnowacyjnych usług doradczych, które pozwolą w kilku
            następujących po sobie etapach dokonać analizy optymalnej strategii
            wdrożenia innowacji w PPHU MARCUS oraz stworzyć model biznesowy
            maksymalizujący korzyści oraz uruchomić produkcję nowego
            wielowarstwowego materiału izolacyjnego dla przemysłu budowlanego na
            bazie tzw. szarpanki bawełnianej. Założenia techniczne planowanej do
            wdrożenia innowacji powstały w oparciu o przeprowadzone w firmie
            MARCUS prace B+R. Całkowity koszt realizacji projektu – 1.039.350,00
            zł Wkład Funduszy Europejskich – maksymalnie 444.500,00 zł
          </p>
        </StyledSection>
        <StyledSection>
          <StyledSectionTitle>ZAKRES PROJEKTU OBEJMUJE:</StyledSectionTitle>
          <StyledListParagraph>
            • Analizę alternatywnych ścieżek rozwoju poprzez wdrażanie
            innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Uszczegółowienie i ocenę wybranej ścieżki rozwoju związanej z
            wdrażaniem innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Przygotowanie szczegółowego modelu finansowego dla opracowywanej
            lub wdrażanej innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Identyfikację i mapowanie kluczowych procesów biznesowych
            związanych z wdrażaniem innowacji, ich modyfikacji i optymalizacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Pomoc w opracowaniu dokumentacji funkcjonalnej lub technicznej
            niezbędnej do wdrożenia innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Analizę ryzyka wdrożenia innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Doradztwo, pomoc i szkolenia w pełnym wdrożeniu innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Monitorowanie i ocenę efektów wdrażania innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Analizę wpływu wdrożenia innowacyjnej technologii na środowisko
            naturalne;
          </StyledListParagraph>
          <StyledListParagraph>
            • Opracowanie strategii marketingowej dla wyrobu lub usługi będącej
            przedmiotem wdrożenia innowacji;
          </StyledListParagraph>
          <StyledListParagraph>
            • Pozostałe uzasadnione doradztwo niezbędne do wdrożenia innowacji
            technicznej.
          </StyledListParagraph>
          <p>
            Celem projektu jest przygotowanie do wdrożenia innowacji w postaci
            własnej technologii wytwarzania nowego na rynku materiału
            izolacyjnego na bazie porosa dla sektora budowlanego oraz
            osiągniecie przewagi konkurencyjnej na rynku krajowym i
            międzynarodowym. Cel ten zostanie osiągnięty poprzez zakup usług
            proinnowacyjnych od IOB, realizowanych przez doświadczonych
            ekspertów oraz uruchomienie produkcji. Usługi będą miały charakter
            marketingowy (strategia wdrożenia), finansowy (analiza efektywności)
            oraz techniczny.
          </p>
        </StyledSection>
        <StyledSection>
          <StyledSectionTitle>REZULTATY PROJEKTU</StyledSectionTitle>
          <StyledListParagraph>
            • dokumentacja przedstawiająca realizację kolejnych etapów usług
            proinnowacyjnych,
          </StyledListParagraph>
          <StyledListParagraph>
            • urządzenia do niezbędne do produkcji i wdrożenia innowacji.
          </StyledListParagraph>
          <p>
            Natomiast efektem z punktu widzenia prowadzonej działalności będzie
            wdrożenie własnej innowacyjnej technologii do produkcji oraz
            wdrożenie nowego materiału wielowarstwowego izolacyjnego,
            konkurencyjnego na rynku izolacji budowlanych.
          </p>
        </StyledSection>
      </StyledContentWrapper>
    </>
  )
}

export const query = graphql`
  {
    maps_background: file(name: { eq: "maps_background" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hero_image_painting: file(name: { eq: "hero_image_painting" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default InvestmentsPage
